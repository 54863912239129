// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-style-js": () => import("./../../../src/pages/about/style.js" /* webpackChunkName: "component---src-pages-about-style-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-no-found-index-js": () => import("./../../../src/pages/noFound/index.js" /* webpackChunkName: "component---src-pages-no-found-index-js" */),
  "component---src-pages-no-found-style-js": () => import("./../../../src/pages/noFound/style.js" /* webpackChunkName: "component---src-pages-no-found-style-js" */),
  "component---src-pages-stack-index-js": () => import("./../../../src/pages/stack/index.js" /* webpackChunkName: "component---src-pages-stack-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/work-template.js" /* webpackChunkName: "component---src-templates-work-template-js" */)
}

